/* eslint-disable require-await */
import type { Rule } from 'ant-design-vue/es/form'

export const useValidationForm = () => {
  const { $checkIsEmailValid } = useNuxtApp()

  const validateRequired = async (_rule: Rule, value: string) => {
    if (isEmpty(value)) {
      return Promise.reject(new Error('Field ini tidak boleh kosong!'))
    }
    return Promise.resolve()
  }

  const validateRequiredPass = async (_rule: Rule, value: string) => {
    if (isEmpty(value)) {
      return Promise.reject(new Error('Field ini tidak boleh kosong!'))
    }

    const reg = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]+$/
    if (value.length < 8 || !reg.test(value)) {
      return Promise.reject(
        new Error('Minumum 8 karakter terdiri dari huruf dan angka!')
      )
    }
    return Promise.resolve()
  }

  const validateString = async (_rule: Rule, value: string) => {
    if (!value) {
      return Promise.reject(new Error('Field ini tidak boleh kosong!'))
    }
    if (isEmpty(value.trim())) {
      return Promise.reject(new Error('Masukkan dengan format yang benar!'))
    }
    return Promise.resolve()
  }

  const validateEmailOnly = async (_rule: Rule, value: string) => {
    if (!$checkIsEmailValid(value)) {
      return Promise.reject(new Error('Masukkan dengan format yang benar!'))
    }
    return Promise.resolve()
  }

  const validateEmail = async (_rule: Rule, value: string) => {
    if (!value) {
      return Promise.reject(new Error('Tolong masukkan email!'))
    }
    if (!$checkIsEmailValid(value)) {
      return Promise.reject(new Error('Masukkan dengan format yang benar!'))
    }
    return Promise.resolve()
  }

  const validateNumberOnly = async (_rule: Rule, value: string) => {
    const reg = /^\d+$/
    if (!reg.test(value)) {
      return Promise.reject(new Error('Masukkan dengan format yang benar!'))
    }
    return Promise.resolve()
  }

  const validatePhoneNumberOnly = async (_rule: Rule, value: string) => {
    const reg = /^(\d{1,3})?\d{8,}$/
    if (value && !reg.test(value)) {
      return Promise.reject(new Error('Masukkan dengan format yang benar!'))
    }
    return Promise.resolve()
  }

  return {
    validateRequired,
    validateRequiredPass,
    validateString,
    validateEmail,
    validateEmailOnly,
    validateNumberOnly,
    validatePhoneNumberOnly
  }
}
